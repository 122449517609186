import React, { FunctionComponent, useContext, useState } from 'react'
import { UserContext, InventoryItem } from './UserGetter'
import { getInventoryItemIcon } from '../utils'
import InventoryItemOverlay from './InventoryItemOverlay'

import DramdriveIcon from '../assets/icons/icon-dramdrive.png'

const InventoryList: FunctionComponent = () => {
  const [activeInventoryItem, setActiveInventoryItem] =
    useState<InventoryItem | null>()

  const { user } = useContext(UserContext)

  return (
    <div className="inventory p-b-3">
      {user && user.inventory.length > 0 && (
        <div className="inventory-header bold color-white font-xl">
          {`My DramDrive  (${user.inventory.length})`}
        </div>
      )}
      {(!user || user.inventory.length <= 0) && (
        <div className="flex column justify-center align-center m-3">
          <img
            alt="dramdrive-icon"
            className="dramdrive-icon"
            src={DramdriveIcon}
          />
          <div className="color-white font-xl bold text-center m-t-3">
            Your Dramdrive <br />
            is Empty
          </div>
        </div>
      )}
      <div className="item-list flex wrap m-b-3">
        {user &&
          user.inventory
            .filter((item) => item.type === 'video' || item.type === 'pdf')
            .map((item) => (
              <div
                className={[
                  'drive-item',
                  'flex',
                  'align-center',
                  'column',
                  'fade-in',
                  'pointer',
                ].join(' ')}
                key={item.code}
                onClick={() => setActiveInventoryItem(item)}
              >
                <img
                  src={getInventoryItemIcon(item.type)}
                  alt="video icon"
                  className="item-icon"
                />
                <div className="item-title color-white text-center">
                  {item.name}
                </div>
              </div>
            ))}
      </div>

      {activeInventoryItem && (
        <InventoryItemOverlay
          item={activeInventoryItem}
          onCloseOverlay={() => {
            setActiveInventoryItem(null)
          }}
        />
      )}
    </div>
  )
}

export default InventoryList
