import React, { FunctionComponent, useContext, useState } from 'react'
import { UserContext, Achievement } from './UserGetter'
import AchievementDetail from './AchievementDetail'
import { getBadgeIcon, achievementCodeToCategory } from '../utils'
import { segment } from '../utils/analytics'

export enum Categories {
  OMEGA_MART = 'Omega Mart',
  FACTORY = 'Factory',
  DRAMCORP = 'Dramcorp',
}

const AchievementsList: FunctionComponent = () => {
  const { user } = useContext(UserContext)
  const [activeCat, setActiveCat] = useState<string | undefined>(undefined)
  const [activeAchievement, setActiveAchievement] = useState<
    Achievement | undefined
  >()

  if (!user) return null

  const filteredAchievements = (): Achievement[] => {
    const filter = activeCat || Categories.OMEGA_MART

    /* Special Magic Strings * */
    // 'res-fact-takeover-achievement'
    // 'res-om-takeover-achievement'

    const filtered = user.achievements.filter(
      (a) => achievementCodeToCategory(a.code) === filter
    )

    // If user has a special achievement with same category as the
    // active category, then add special achievement to the return array.
    if (
      user.achievements.some(
        (a) => a.name === 'res-fact-takeover-achievement'
      ) &&
      filtered.some((f) => f.code === 'fact')
    ) {
      filtered.push(
        user.achievements.find(
          (a) => a.name === 'res-fact-takeover-achievement'
        ) as Achievement
      )
    }

    if (
      user.achievements.some((a) => a.name === 'res-om-takeover-achievement') &&
      filtered.some((f) => f.code === 'om')
    ) {
      filtered.push(
        user.achievements.find(
          (a) => a.name === 'res-om-takeover-achievement'
        ) as Achievement
      )
    }

    if (
      user.achievements.some((a) => a.name === 'res-dcom-takeover') &&
      filtered.some((f) => f.code === 'dcom')
    ) {
      filtered.push(
        user.achievements.find(
          (a) => a.name === 'res-dcom-takeover'
        ) as Achievement
      )
    }

    return filtered
  }

  const handleAchievementClick = (category: Categories): void => {
    setActiveCat(category)
    segment.achievementsFilterClick(category)
  }

  const handleClose = (achievement: Achievement): void => {
    segment.achievementsDetailClose(achievement.name)
    setActiveAchievement(undefined)
  }

  const handleOpen = (achievement: Achievement): void => {
    segment.achievementsDetailOpen(achievement.name)
    setActiveAchievement(achievement)
  }

  return (
    <div className="achievements flex column align-cente p-b-3">
      {activeAchievement && (
        <AchievementDetail
          achievement={activeAchievement}
          achievements={user.achievements}
          onClose={() => handleClose(activeAchievement)}
        />
      )}
      <div className="achievement-select-container p-y-1 w-100 flex justify-center m-t-2">
        <select
          className="achievements-filter color-white font-lg bold"
          onChange={(e) => handleAchievementClick(e.target.value as Categories)}
        >
          <option
            key={Categories.OMEGA_MART}
            value={Categories.OMEGA_MART}
            className="font-lg color-white bold"
          >
            {Categories.OMEGA_MART}
          </option>

          <option
            key={Categories.FACTORY}
            value={Categories.FACTORY}
            className="font-lg color-white bold"
          >
            {Categories.FACTORY}
          </option>

          <option
            key={Categories.DRAMCORP}
            value={Categories.DRAMCORP}
            className="font-lg color-white bold"
          >
            {Categories.DRAMCORP}
          </option>
        </select>
      </div>

      <div className="achievements-filter-control-container p-l-3 w-50">
        <button
          type="button"
          className={`achievements-filter-button font-xl ${
            activeCat === Categories.OMEGA_MART || !activeCat
              ? 'color-white bold active'
              : 'color-grey'
          }`}
          onClick={() => handleAchievementClick(Categories.OMEGA_MART)}
        >
          Omega Mart
        </button>
        <button
          type="button"
          className={`achievements-filter-button font-xl ${
            activeCat === Categories.FACTORY
              ? 'color-white bold active'
              : 'color-grey'
          }`}
          onClick={() => handleAchievementClick(Categories.FACTORY)}
        >
          Factory
        </button>
        <button
          type="button"
          className={`achievements-filter-button font-xl ${
            activeCat === Categories.DRAMCORP
              ? 'color-white bold active'
              : 'color-grey'
          }`}
          onClick={() => handleAchievementClick(Categories.DRAMCORP)}
        >
          Dramcorp
        </button>
      </div>

      <div className="achievements-list p-y-2 m-b-3">
        {filteredAchievements().map((cheevo) => (
          <div
            key={cheevo.code}
            className="achievement-item flex align-center column fade-in m-b-2"
            onClick={() => handleOpen(cheevo)}
          >
            <img
              src={getBadgeIcon(cheevo.code)}
              alt="achievement icon"
              className="achievement-icon"
            />
            <div className="achievement-name color-white font-xl text-center mw-50">
              {cheevo.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AchievementsList
