import React, { Fragment } from 'react'

import OMIcon from '../../assets/FTUE/OM_icon.png'
import Card from '../../assets/FTUE/card_front_back.png'
import Dramports from '../../assets/FTUE/dramports.png'
import Cheevos from '../../assets/FTUE/cheevos.png'
import OmegaMartAvatar from '../../assets/FTUE/omegamart_avatar.png'
import DramdriveIcon from '../../assets/FTUE/dramdrive_icon.png'
import ScanGuideIcon from '../../assets/FTUE/scan_guide.png'
import Explore from '../../assets/FTUE/explore.png'

export interface Page {
  id: number
  illustrationArea: React.ReactElement
  blurb: React.ReactElement
}

const pages: Array<Page> = [
  {
    id: 1,
    illustrationArea: (
      <img
        src={OMIcon}
        alt="Omega Mart Exterior"
        className="ftue-icon omega-mart"
      />
    ),
    blurb: (
      <p>
        Wow, you’re HIRED!
        <br />
        Welcome, Omega Mart Trainee.
      </p>
    ),
  },
  {
    id: 2,
    illustrationArea: (
      <Fragment>
        <img
          src={Card}
          alt="Omega Mart Access Card"
          className="ftue-icon card"
        />
      </Fragment>
    ),
    blurb: (
      <p>
        This is your
        <br />
        Omega Mart Access Card
      </p>
    ),
  },
  {
    id: 3,
    illustrationArea: (
      <Fragment>
        <img
          src={Dramports}
          className="ftue-icon dramport"
          alt="3 Dramports and an access card"
        />
      </Fragment>
    ),
    blurb: (
      <p>
        Boop your card at
        <br />
        Dramports to
        <br />
        access training…
      </p>
    ),
  },
  {
    id: 4,
    illustrationArea: (
      <img
        src={Cheevos}
        alt="Achievements that can get gained"
        className="ftue-icon achievements"
      />
    ),
    blurb: <p>... to unlock achievements</p>,
  },
  {
    id: 5,
    illustrationArea: (
      <Fragment>
        <img
          src={OmegaMartAvatar}
          alt="DramCorp Robot Avatar"
          className="ftue-icon "
        />
      </Fragment>
    ),
    blurb: <p>... and rise through the ranks of the Dram-ily.</p>,
  },
  {
    id: 6,
    illustrationArea: (
      <img
        src={DramdriveIcon}
        alt="Dramdrive Icon"
        className="ftue-icon drive-icon"
      />
    ),
    blurb: <p>Add content to your DramDrive as you go</p>,
  },
  {
    id: 7,
    illustrationArea: (
      <img
        src={ScanGuideIcon}
        alt="Scan Instructions"
        className="ftue-icon scan-icon"
      />
    ),
    blurb: <p>And view it later by scanning your card’s QR code</p>,
  },
  {
    id: 8,
    illustrationArea: (
      <img
        src={Explore}
        alt="I dont know anymore"
        className="ftue-icon explore"
      />
    ),
    blurb: <p>Now go get to know Omega Mart!</p>,
  },
]

const uniqueIds = new Set(pages.map((page) => page.id))

if (uniqueIds.size !== pages.length) {
  throw new Error('duplicate id found in the page array')
}

export default pages
