import { useAuth0 } from '@auth0/auth0-react'
import classNames from 'classnames'
import { useState } from 'react'

const LogoutContainer: React.FunctionComponent = () => {
  const { isAuthenticated, logout, user, isLoading } = useAuth0()
  const [open, setOpen] = useState(false)
  const handleToggle = (): void => {
    setOpen(!open)
  }

  if (!isAuthenticated || isLoading || !user) return <></>
  return (
    <div className="logout-container justify-end m-x-2 w-100">
      <button
        type="button"
        className="avatar-container"
        onClick={() => handleToggle()}
      >
        <img alt="avatar" src={user.picture} />
      </button>
      <div className={classNames('logout-menu', { open })}>
        <div className="menu-item">Welcome {user.name}</div>
        <hr />
        <button
          // return user to access.omegamart.com (Login Screen)
          onClick={() =>
            logout({
              logoutParams: { returnTo: process.env.REACT_APP_ROOT_URL },
            })
          }
          type="button"
          className="menu-item"
        >
          Logout
        </button>
        <button
          onClick={() => handleToggle()}
          type="button"
          className="menu-item"
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default LogoutContainer
