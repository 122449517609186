import React, { FunctionComponent } from 'react'

interface Props {
  title: string
  source: string
}
const VideoViewer: FunctionComponent<Props> = ({ title, source }) => (
  <div className="video" style={{ background: 'black', height: '100%' }}>
    <iframe
      src={`https://player.vimeo.com/video/${source}`}
      width="100%"
      height="100%"
      title={title}
      allowFullScreen
    />
  </div>
)

export default VideoViewer
