/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Analytics } from 'analytics'
import segmentPlugin from '@analytics/segment'
import { Categories } from '../components/AchievementsList'
import { IS_PRODUCTION, SEGMENT_KEY } from '../constants'

const analytics = Analytics({
  app: 'Vegas Web Inventory Viewer',
  plugins: [
    segmentPlugin({
      writeKey: SEGMENT_KEY,
    }),
  ],
})

export const segment = {
  pageTracking: () => analytics.page(),
  identify: (user: string) => analytics.identify(user),
  menuClick: (menuTitle: string) => {
    if (!IS_PRODUCTION) return
    // analytics.track(`UserInputClick`, {
    //   menuTitle,
    // })
  },
  achievementsFilterClick: (category: Categories) => {
    if (!IS_PRODUCTION) return
    // analytics.track('UserInputClick', {
    //   category,
    // })
  },
  achievementsDetailClose: (title: string) => {
    if (!IS_PRODUCTION) return
    // analytics.track('UserInputClick', {
    //   title,
    // })
    // analytics.track('MediaConsumeEnd', {
    //   title,
    // })
  },
  achievementsDetailOpen: (title: string) => {
    if (!IS_PRODUCTION) return
    // analytics.track('UserInputClick', {
    //   title,
    // })
    // analytics.track('MediaConsumeStart', {
    //   title,
    // })
  },
}
