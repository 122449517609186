import React, { FunctionComponent, useRef, useState } from 'react'
import classNames from 'classnames'
import { Achievement } from './UserGetter'
import { achievementCodeToCategory, getBadgeIcon } from '../utils'
import CloseButton from './CloseButton'

import { Categories } from './AchievementsList'

import RightArrow from '../assets/icons/icon-arrow-right.svg'
import LeftArrow from '../assets/icons/icon-arrow-left.svg'

interface Props {
  achievements: Array<Achievement>
  achievement: Achievement
  onClose: () => void
}

const AchievementDetail: FunctionComponent<Props> = ({
  achievements,
  achievement,
  onClose,
}) => {
  const [activeCat, setActiveCat] = useState<string>(
    achievementCodeToCategory(achievement.code)
  )
  const [activeAchievement, setActiveAchievement] =
    useState<Achievement>(achievement)

  const currentIndex = useRef(
    achievements
      .filter((a) => achievementCodeToCategory(a.code) === activeCat)
      .map((a) => a.name)
      .indexOf(achievement.name)
  )

  const handleCatSelect = (cat: string): void => {
    setActiveCat(cat)
    const selectedAchievement = achievements.filter(
      (a) => achievementCodeToCategory(a.code) === cat
    )[0]

    setActiveAchievement(selectedAchievement)
  }

  const handleNav = (dir: 'inc' | 'dec'): void => {
    const currentTypes = achievements.filter(
      (a) => achievementCodeToCategory(a.code) === activeCat
    )

    let nextIndex = 0
    if (!currentIndex) nextIndex = 0

    if (dir === 'inc') {
      nextIndex = currentIndex.current + 1
    }
    if (dir === 'dec') {
      nextIndex = currentIndex.current - 1
    }

    if (nextIndex > currentTypes.length - 1) nextIndex = 0
    if (nextIndex < 0) nextIndex = currentTypes.length - 1

    setActiveAchievement(currentTypes[nextIndex])
    currentIndex.current = nextIndex
  }

  if (!achievement) return null
  return (
    <div className="achievement-detail overlay column">
      <CloseButton
        classNames={['achievement-detail-close']}
        onClick={() => onClose()}
      />
      <div className="achievement-detail-content">
        <div className="achievements-filter-control-container">
          <button
            type="button"
            className={classNames(
              'achievements-filter-button',
              'font-xl',
              { 'color-grey': activeCat !== Categories.OMEGA_MART },
              { 'color-white bold active': activeCat === Categories.OMEGA_MART }
            )}
            onClick={() => handleCatSelect(Categories.OMEGA_MART)}
          >
            Omega Mart
          </button>
          <button
            type="button"
            className={classNames(
              'achievements-filter-button',
              'font-xl',
              { 'color-grey': activeCat !== Categories.FACTORY },
              { 'color-white bold active': activeCat === Categories.FACTORY }
            )}
            onClick={() => handleCatSelect(Categories.FACTORY)}
          >
            Factory
          </button>
          <button
            type="button"
            className={classNames(
              'achievements-filter-button',
              'font-xl',
              { 'color-grey': activeCat !== Categories.DRAMCORP },
              { 'color-white bold active': activeCat === Categories.DRAMCORP }
            )}
            onClick={() => handleCatSelect(Categories.DRAMCORP)}
          >
            Dramcorp
          </button>
        </div>

        <div className="achievement-info flex column align-center fade-in">
          <div
            className="achievement-detail-control"
            onClick={() => handleNav('dec')}
          >
            <img
              alt="left arrow button"
              src={LeftArrow}
              className="arrow-icon"
            />
          </div>

          <div className="info-inner flex column align-center fade-in">
            <img
              src={getBadgeIcon(activeAchievement.code)}
              alt="achievement icon"
              className="achievement-icon m-t-3"
            />

            <div className="flex justify-center align-center column">
              <div className="achievement-name color-white bold font-lg p-y-1 w-80">
                {activeAchievement.name}
              </div>
              <div className="achievement-detail-description w-80 color-white ">
                {activeAchievement.description}
              </div>
            </div>
          </div>

          <div
            className="achievement-detail-control"
            onClick={() => handleNav('inc')}
          >
            <img
              alt="right arrow button"
              className="arrow-icon"
              src={RightArrow}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AchievementDetail
