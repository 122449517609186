import Cookies from 'js-cookie'
import React, {
  FunctionComponent,
  useEffect,
  useState,
  createContext,
  useMemo,
  PropsWithChildren,
} from 'react'
import { LOGIN_FAILURE_COOKIE } from '../App'

interface StructuredPropertyFromPlatform {
  value: any
  item: {
    code: string
  }
}

interface InventoryItemFromPlatfrom {
  type: string
  name: string
  code: string
  source?: string
  anchor?: string
  description?: string
  configJson?: any
}

interface InventorySlotFromPlatform {
  quantity: number
  item: InventoryItemFromPlatfrom
}

export interface InventoryItem {
  type: string
  name: string
  code: string
  source?: string
  spendable?: boolean
}

export interface Achievement extends InventoryItem {
  anchor: string
  description: string
}

interface IUser {
  OMEmployeeType: string
  OMEmployeeLevel: number
  inventory: Array<InventoryItem>
  achievements: Array<Achievement>
  identifier: string
  hasJoinedResistance: boolean
  _id: string
}

interface IUserContext {
  user?: IUser
  loading: boolean
}

const parseInventory: (
  slots: Array<InventorySlotFromPlatform>
) => Array<InventoryItem> = (inventorySlots) =>
  inventorySlots
    .filter((slot) => slot.item.type !== 'achievement')
    .filter((slot) => slot.quantity)
    .map((slot) =>
      slot.item.type !== 'video'
        ? slot.item
        : {
            ...slot.item,
            source: slot.item.configJson.vimeoId,
          }
    )

const parseAchievements: (
  slots: Array<InventorySlotFromPlatform>
) => Array<Achievement> = (inventorySlots) =>
  inventorySlots
    .filter((slot) => slot.item.type === 'achievement')
    .filter((slot) => slot.quantity)
    .map((slot) => ({
      ...slot.item,
      anchor: slot.item.anchor || 'Mysterious Unanchored Achievement',
      description: slot.item.description || 'No description provided',
    }))

// defaults only used for testing
export const UserContext = createContext<IUserContext>({
  loading: true,
  user: {
    OMEmployeeType: 'Test Employee Type',
    OMEmployeeLevel: 1,
    inventory: [
      {
        type: 'test type',
        name: 'test name',
        code: 'test code',
        source: 'test source',
      },
    ],
    hasJoinedResistance: false,
    achievements: [],
    identifier: 'abc123',
    _id: '',
  },
})

export interface incomingUser {
  id: string
  type: 'alpha' | 'eos'
}

interface Props {
  user: incomingUser
}

const UserGetter: FunctionComponent<PropsWithChildren<Props>> = ({
  user,
  children,
}) => {
  const [returnedUser, setReturnedUser] = useState<IUser>()
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    async function goGetThatUserFromTheAPI(
      incomingUser: incomingUser
    ): Promise<void> {
      try {
        const endpoint = incomingUser.type === 'alpha' ? 'alpha' : 'uid'
        const res = await fetch(
          `${process.env.REACT_APP_USER_API}/${endpoint}/${incomingUser.id}`
        )

        const json = await res.json()
        const joinedResistance: StructuredPropertyFromPlatform =
          json?.structuredProperties?.find(
            (prop: StructuredPropertyFromPlatform) =>
              prop.item.code === 'askedToProveThemself'
          )

        const employeeTypeFromPlatform: StructuredPropertyFromPlatform =
          json?.structuredProperties?.find(
            (prop: StructuredPropertyFromPlatform) =>
              prop.item.code === 'OMEmployeeType'
          )
        const employeeType = employeeTypeFromPlatform
          ? employeeTypeFromPlatform.value
          : 'OmegaMart'

        const { _id } = json
        setReturnedUser({
          OMEmployeeType: employeeType,
          inventory: parseInventory(json.inventory),
          achievements: parseAchievements(json.inventory),
          identifier: incomingUser.id,
          OMEmployeeLevel: 1,
          hasJoinedResistance: joinedResistance
            ? joinedResistance.value
            : false,
          _id,
        })

        setLoading(false)

        Cookies.remove(LOGIN_FAILURE_COOKIE)
      } catch (err) {
        console.error(err)
        setLoading(false)
        // If we fail to fetch the user, reload to show the
        // login screen
        // Cookies.set(LOGIN_FAILURE_COOKIE, 'true')
        // window.location.href = window.location.origin
      }
    }
    goGetThatUserFromTheAPI(user)
  }, [user])

  return (
    <UserContext.Provider
      value={useMemo(
        () => ({ user: returnedUser, loading }),
        [returnedUser, loading]
      )}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserGetter
