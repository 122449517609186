import React, { FunctionComponent } from 'react'
import { InventoryItem } from './UserGetter'
import VideoViewer from './VideoViewer'
import PDFViewer from './PDFViewer'
import closeIcon from '../assets/icons/ic_close.svg'

interface Props {
  item: InventoryItem
  onCloseOverlay?: () => void
}

const InventoryItemOverlay: FunctionComponent<Props> = ({
  item,
  onCloseOverlay,
}) => {
  let viewer
  switch (true) {
    case item.type === 'video':
      viewer = (
        <VideoViewer
          title={item.name}
          // source needs to be set to something,
          // we could set a default value if we want,
          // or let vimeo error out and display its own message.
          source={item.source ? item.source : ''}
        />
      )
      break
    case item.type === 'pdf':
      viewer = <PDFViewer code={item.code} />
      break
    default:
      viewer = <div />
  }

  return (
    <div
      className="overlay"
      data-item-type={item.type}
      onClick={() => onCloseOverlay && onCloseOverlay()}
    >
      <div className="overlay-inner">{viewer}</div>
      <div onClick={() => onCloseOverlay && onCloseOverlay()}>
        <img alt="close overlay" className="overlay-close" src={closeIcon} />
      </div>
    </div>
  )
}

InventoryItemOverlay.defaultProps = {
  onCloseOverlay: undefined,
}

export default InventoryItemOverlay
