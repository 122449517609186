import React, { FunctionComponent, useContext } from 'react'
import { UserContext } from './UserGetter'
import { getAvatar, determinePlayerLevel, PlayerLevel } from '../utils'
import DefaultAvatar from '../assets/avatars/avatar-omegamart.png'

interface Props {
  userAlpha: string
}

const Bio: FunctionComponent<Props> = ({ userAlpha }: Props) => {
  const { user } = useContext(UserContext)

  const playerLevel = (playerEnum: PlayerLevel): string => {
    if (playerEnum === PlayerLevel.Factory) return 'Factory Employee'
    if (playerEnum === PlayerLevel.Dramcorp) return 'Dramcorp Employee'
    return 'Omega Mart Employee'
  }

  return (
    <div className="bio p-y-2 fade-in">
      <div className="bio-info">
        <div className="bold color-white text-center font-lg bio-greeting">
          Hello!
        </div>
        <div className="font-lg text-center color-white bio-id">
          ID#:
          {user ? user.identifier : userAlpha}
        </div>
        <h1 className="font-xl color-white text-center mw-50 m-x-auto bio-type">
          {user
            ? playerLevel(determinePlayerLevel(user.achievements))
            : 'Omega Mart'}
        </h1>
      </div>

      <div className="avatar fade-in">
        <img
          alt="user-avatar"
          className="avatar-image"
          src={
            user
              ? getAvatar(user.OMEmployeeType, user.hasJoinedResistance)
              : DefaultAvatar
          }
        />
      </div>
    </div>
  )
}

export default Bio
