import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import CloseButton from './CloseButton'
import DramcorpLogo from '../assets/logos/logo-dramcorp.svg'
import { MENU_OPTIONS } from '../types/shared'

interface Props {
  menuOpen: boolean
  setMenuOpen: (value: React.SetStateAction<boolean>) => void
  handleMenuClick: (option: MENU_OPTIONS, closeMenu?: boolean) => void
  activeView: number
}
const MobileMenu: React.FunctionComponent<Props> = ({
  handleMenuClick,
  activeView = 0,
  menuOpen,
  setMenuOpen,
}: Props) => {
  const { isAuthenticated, logout } = useAuth0()
  if (!menuOpen) return <></>
  return (
    <div className="menu">
      <div className="menu-header flex justify-center align-center">
        <CloseButton
          classNames={['menu-close']}
          onClick={() => setMenuOpen(false)}
        />
        <img alt="Dramcorp logo" className="menu-logo" src={DramcorpLogo} />
      </div>
      <div className="flex column align-center p-t-3 fade-in">
        <button
          type="button"
          className={`btn menu-btn ${activeView === 0 ? 'active' : ''}`}
          onClick={() => handleMenuClick(MENU_OPTIONS.Dashboard, true)}
        >
          Dashboard
        </button>
        <button
          type="button"
          className={`btn menu-btn  ${activeView === 1 ? 'active' : ''}`}
          onClick={() => handleMenuClick(MENU_OPTIONS.DramDrive, true)}
        >
          My DramDrive
        </button>
        {isAuthenticated && (
          <button
            type="button"
            className="btn menu-btn"
            onClick={() => logout()}
          >
            Logout
          </button>
        )}
      </div>
    </div>
  )
}

export default MobileMenu
