import React, { FunctionComponent } from 'react'

interface Props {
  code: string
}
const PDFViewer: FunctionComponent<Props> = ({ code }) => {
  const pdfSource = (pdfTitle: string): string => {
    switch (pdfTitle) {
      // Revisit if this is the best way to do this
      case 'ximena-dramcorp-takeover-document':
        return 'https://uploads-ssl.webflow.com/5e165efe815ce5395714643b/641c92377d478461679bb1c8_Ximena_Dramcorp.pdf'
      case 'lora-omegamart-takeover-document':
        return 'https://uploads-ssl.webflow.com/5e165efe815ce5395714643b/641b802df923122391f2525b_Lora_OmegaMart.pdf'
      case 'jesse-factory-takeover-document':
        return 'https://uploads-ssl.webflow.com/5f6e35149f32c5b73275b09e/602610ecdcd77b16c33d80ef_Jesse_Takeover.pdf'
      case 'cecelia-welcome-note':
        // eslint-disable-next-line max-len
        return 'https://uploads-ssl.webflow.com/5f6e35149f32c5b73275b09e/630d0b06f2f0b152702da77a_WelcomeLetter_Cecelia.pdf'
      default:
        return ':)'
    }
  }

  return (
    <object
      aria-label="pdf viewer"
      type="application/pdf"
      width="100%"
      height="100%"
      data={`${pdfSource(code)}#toolbar=0`}
      data-testid="pdf-viewer"
    />
  )
}

export default PDFViewer
