import React, { FunctionComponent } from 'react'

import CloseIcon from '../assets/icons/icon-close.svg'

interface Props {
  classNames?: Array<string>
  onClick?: () => void
}

const CloseButton: FunctionComponent<Props> = ({
  classNames = [],
  onClick,
}) => (
  <button
    type="button"
    className={`close-btn ${classNames.join(' ')}`}
    onClick={() => onClick && onClick()}
  >
    <img alt="close button" className="close-icon" src={CloseIcon} />
  </button>
)

CloseButton.defaultProps = {
  classNames: undefined,
  onClick: undefined,
}

export default CloseButton
