import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import SyncIcon from '../assets/icons/pair.svg'

interface Props {
  handleClick: () => void
}
const SyncButton: React.FunctionComponent<Props> = ({ handleClick }: Props) => {
  const { isAuthenticated } = useAuth0()
  if (isAuthenticated) return <></>
  return (
    <div className="sync-container">
      <button type="button" onClick={() => handleClick()}>
        <img src={SyncIcon} alt="Sync account" />
      </button>
    </div>
  )
}

export default SyncButton
