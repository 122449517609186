import dramcorpEmployee from './assets/avatars/avatar-dramcorp.png'
import dramcorpEmployeeResistance from './assets/avatars/avatar-dramcorp-resistance.png'
import factoryEmployee from './assets/avatars/avatar-factory.png'
import factoryEmployeeResistance from './assets/avatars/avatar-factory-resistance.png'
import omegaMartEmployee from './assets/avatars/avatar-omegamart.png'
import omegaMartEmployeeResistance from './assets/avatars/avatar-omegamart-resistance.png'

import videoIcon from './assets/icons/video.png'
import imageIcon from './assets/icons/image.png'
import rtfIcon from './assets/icons/rtf.png'

import findMarinNewsBadge from './assets/badges/dramcorp/badge-dc-find-newsof-marin.png'
import spyOnResistanceBadge from './assets/badges/dramcorp/badge-dc-spy-on-the-resistance.png'
import useAurascopeBadge from './assets/badges/dramcorp/badge-dc-lat-aurascope.png'
import adjustSourceDoorBadge from './assets/badges/factory/badge-factory-source-door-control-panel.png'
import motivateWorkforceBadge from './assets/badges/factory/badge-factory-motivate-workforce.png'
import redirectSourceDoorBadge from './assets/badges/factory/badge-factory-redirect-source-achieved.png'
import factoryTakeOverBadge from './assets/badges/factory/badge-factory-vocs-takeover.png'
import coneQuizBadge from './assets/badges/omega-mart/badge-om-cone-quiz.png'
import learnProductsBadge from './assets/badges/omega-mart/badge-om-learn-about-our-products.png'
import watchBenefitsVideoBadge from './assets/badges/omega-mart/badge-om-employee-benefits-video.png'
import omegaMartTakeoverBadge from './assets/badges/omega-mart/badge-om-product-viewer-takeover.png'

import { Achievement } from './components/UserGetter'

export const getInventoryItemIcon: (type: string) => string = (type) => {
  switch (type) {
    case 'video':
      return videoIcon
    case 'markdown':
    case 'pdf':
      return rtfIcon
    case 'image':
      return imageIcon
    default:
      return 'fucked up icon'
  }
}

// TODO there is no see see tv takeover achievement in platform
export const getBadgeIcon: (code: string) => string = (code) => {
  switch (code) {
    case 'om-spill-quiz-achievement':
      return coneQuizBadge
    case 'om-product-samples-achievement':
      return learnProductsBadge
    case 'om-employee-benefits-video-achievement':
      return watchBenefitsVideoBadge
    case 'dcom-help-cecelia-achievement':
      return findMarinNewsBadge
    case 'dcom-spy-on-resistance-achievement':
      return spyOnResistanceBadge
    case 'dcom-aurascope-achievement':
      return useAurascopeBadge
    case 'fact-motivate-workforce-achievement':
      return motivateWorkforceBadge
    case 'fact-adjust-source-door-achievement':
      return adjustSourceDoorBadge
    case 'fact-redirect-source-door-achievement':
      return redirectSourceDoorBadge
    case 'res-fact-takeover-achievement':
      return factoryTakeOverBadge
    case 'res-om-takeover-achievement':
      return omegaMartTakeoverBadge
    default:
      return 'broken badge'
  }
}

export const getAvatar: (
  employeeType: string,
  userHasResisted: boolean
) => string = (employeeType, userHasResisted) => {
  switch (employeeType) {
    case 'Factory':
      return userHasResisted ? factoryEmployeeResistance : factoryEmployee
    case 'Dramcorp':
    case 'DramCorp':
      return userHasResisted ? dramcorpEmployeeResistance : dramcorpEmployee
    default:
      return userHasResisted ? omegaMartEmployeeResistance : omegaMartEmployee
  }
}

export const achievementCodeToCategory: (
  code: string
) => 'Omega Mart' | 'Dramcorp' | 'Factory' | 'Resistance' = (code) => {
  const space = code.split('-')[0]

  switch (space) {
    case 'om':
      return 'Omega Mart'
    case 'dcom':
      return 'Dramcorp'
    case 'fact':
      return 'Factory'
    default:
      return 'Resistance'
  }
}

export enum PlayerLevel {
  OmegaMart,
  Factory,
  Dramcorp,
}
export const determinePlayerLevel: (
  achievements: Array<Achievement>
) => PlayerLevel = (achievements) => {
  if (!achievements.length) return PlayerLevel.OmegaMart
  const hasOMAchievements =
    achievements.some(
      (item) => item.code === 'om-product-samples-achievement'
    ) &&
    achievements.some(
      (item) => item.code === 'om-employee-benefits-video-achievement'
    ) &&
    achievements.some((item) => item.code === 'om-spill-quiz-achievement')
  const hasFactoryAchievements =
    achievements.some(
      (item) => item.code === 'fact-motivate-workforce-achievement'
    ) &&
    achievements.some(
      (item) => item.code === 'fact-adjust-source-door-achievement'
    ) &&
    achievements.some(
      (item) => item.code === 'fact-redirect-source-door-achievement'
    )
  if (hasOMAchievements) {
    if (hasFactoryAchievements) return PlayerLevel.Dramcorp
    return PlayerLevel.Factory
  }
  return PlayerLevel.OmegaMart
}
