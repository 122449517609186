import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import cookie from 'js-cookie'
import classNames from 'classnames'
import { UserContext } from './UserGetter'
import Modal from './Modal'

const monolithPathAchievements = [
  'res-hacked-one-achievement',
  'res-hacked-two-achievement',
  'res-hacked-three-achievement',
  'res-om-takeover-achievement',
  'res-fact-takeover-achievement',
  'res-dcom-takeover',
]

const dramcorpPathAchievements = [
  'om-spill-quiz-achievement',
  'om-product-samples-achievement',
  'om-employee-benefits-video-achievement',
  'dcom-help-cecelia-achievement',
  'dcom-spy-on-resistance-achievement',
  'dcom-aurascope-achievement',
  'fact-motivate-workforce-achievement',
  'fact-adjust-source-door-achievement',
  'fact-redirect-source-door-achievement',
]

const leadershipUrl = 'https://www.dramcorp.com/leadership-ascension-track'
const monolithUrl = 'https://www.dramcorp.com/hacked/dramcorpsucks'

// :D
const cookieName = 'mI8xxZyy7p7qV41abeq1bhc6v'

const hasCookie = (): boolean => !!cookie.get(cookieName)

// TODO: revisit later
// allow users to choose a path if they have completed both
// on a single card
const BOTH_PATH_ENABLED = false

const AchievementPathModal: FunctionComponent = () => {
  const [enabled, setEnabled] = useState(false)
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (!hasCookie()) setEnabled(true)
  }, [])

  if (!user) return <></>

  const achievements = user.achievements.map((achievement) => achievement.code)

  const completedDramcorpPath = dramcorpPathAchievements.every(
    (item) => achievements.indexOf(item) > -1
  )
  const completedMonolithPath = monolithPathAchievements.every(
    (item) => achievements.indexOf(item) > -1
  )

  const dramcorpPathOnly = completedDramcorpPath && !completedMonolithPath
  const monolithPathOnly = completedMonolithPath && !completedDramcorpPath
  const doubleAgent =
    BOTH_PATH_ENABLED && completedDramcorpPath && completedMonolithPath

  const handleOnClick = (): void => {
    cookie.set(cookieName, 'true', { expires: 365 * 10 })
  }

  // if completed either, but not both
  const isVisible =
    enabled &&
    (completedDramcorpPath || completedMonolithPath) && // either
    !(completedDramcorpPath && completedMonolithPath) // both

  const title = (() => {
    if (dramcorpPathOnly) {
      return 'Prepare to Ascend'
    }
    if (monolithPathOnly) {
      return 'Enter the Monolith'
    }
    if (doubleAgent) {
      return 'Time to decide'
    }
    return 'Join us'
  })()

  return (
    <Modal
      isOpen={isVisible}
      title={title}
      classes={classNames({
        'achievement-path-modal': true,
        dramcorp: dramcorpPathOnly || doubleAgent,
        monolith: monolithPathOnly,
      })}
      onClose={() => setEnabled(false)}
    >
      <>
        {dramcorpPathOnly && (
          <>
            You have been chosen to take part in the Leadership Ascension Track.{' '}
            To start your journey, follow the link below{' '}
            <div className="cta-container">
              <a
                className="cta"
                onClick={handleOnClick}
                onAuxClick={handleOnClick}
                href={leadershipUrl}
              >
                Enter
              </a>
            </div>
          </>
        )}
        {monolithPathOnly && (
          <>
            You&apos;ve earned our trust. Now we need to know how to reach you
            when the time is right{' '}
            <div className="cta-container">
              <a
                className="cta"
                onClick={handleOnClick}
                onAuxClick={handleOnClick}
                href={monolithUrl}
              >
                Enter
              </a>
            </div>
          </>
        )}
        {doubleAgent && (
          <div>
            You&apos;ve been busy playing both sides of the fence, haven&apos;t
            you? Well, now you choose.
            <div className="cta-container">
              <a
                className="cta"
                onClick={handleOnClick}
                onAuxClick={handleOnClick}
                href={monolithUrl}
              >
                Help the Monolith
              </a>
              <span>or</span>
              <a
                className="cta"
                onClick={handleOnClick}
                onAuxClick={handleOnClick}
                href={leadershipUrl}
              >
                Leadership Ascension Track
              </a>
            </div>
          </div>
        )}
      </>
    </Modal>
  )
}

export default AchievementPathModal
