import React, { useState, Fragment, useEffect } from 'react'

import './App.css'

import { startCase } from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'
import UserGetter, { incomingUser } from './components/UserGetter'

import AchievementsList from './components/AchievementsList'

import Bio from './components/Bio'
import FTUE from './components/FTUE/FTUE'
import InventoryList from './components/InventoryList'

import DramcorpLogo from './assets/logos/logo-dramcorp.svg'
import MenuIcon from './assets/icons/icon-menu.svg'
import AchievementPathModal from './components/AchievementPathModal'
import LoginModal from './components/LoginModal/LoginModal'
import { segment } from './utils/analytics'
import { IS_PRODUCTION } from './constants'
import SyncButton from './components/SyncButton'
import Auth0SyncSlideout from './components/Auth0SyncSlideOut'
import LogoutContainer from './components/LogoutContainer'
import { MENU_OPTIONS } from './types/shared'
import MobileMenu from './components/MobileMenu'
import Modal from './components/Modal'

const alphaMatch = window.location.pathname.match(/[A-Za-z0-9]{6,}/)
export const LOGIN_FAILURE_COOKIE = 'login_failure'

const UserPortal: React.FC = () => {
  // You would be right to think this is weird.
  // Confirm we have a uid from query param -
  // If we don't, then let userGetter deal with it.

  // This was to solve a React problem where uid evaluated to falsy
  // even though the url was valid. useEffect was triggering before parsing was done.
  const [userAlpha] = useState<string>(
    alphaMatch ? alphaMatch[0].toUpperCase() : ''
  )
  const [activeView, setActiveView] = useState<number>(0)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [authOverlayOpen, setAuthOverlayOpen] = useState<boolean>(false)
  const [error, setError] = useState<
    { title: string; description: string } | undefined
  >()

  const {
    isAuthenticated,
    user: auth0user,
    error: auth0error,
    isLoading,
  } = useAuth0()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.has('error') && params.has('error_description')) {
      const title = startCase(params.get('error') as string)
      const description = params.get('error_description') as string
      setError({
        title,
        description,
      })
    }
    if (auth0error) {
      setError({
        title: auth0error.name,
        description: auth0error.message,
      })
    }
    if (error) {
      window.history.pushState({}, document.title, '/')
    }
  }, [])

  useEffect(() => {
    const currentUser = auth0user?.email || userAlpha
    if (!IS_PRODUCTION) return
    segment.pageTracking()
    if (!currentUser) return
    segment.identify(currentUser)
  }, [userAlpha, isAuthenticated])

  const handleAuthOverlayClick = (): void => {
    setAuthOverlayOpen(!authOverlayOpen)
  }

  const handleMenuClick = (option: MENU_OPTIONS, closeMenu?: boolean): void => {
    if (closeMenu) setMenuOpen(false)
    setActiveView(option)
    segment.menuClick(MENU_OPTIONS[option])
  }

  const vegasEosId = auth0user?.['https://meowwolf.com/eos']?.lvs_eos_id
  const vegasAuthenticated = isAuthenticated && vegasEosId
  const authenticated = vegasAuthenticated || !!userAlpha

  const user: incomingUser = {
    id: auth0user ? vegasEosId : userAlpha,
    type: auth0user ? 'eos' : 'alpha',
  }

  const handleErrorModalClose = (): void => {
    setError(undefined)
  }
  const undefinedErrorMessage =
    'An undefined error occured. Please try again or contact Meow Wolf Customer Support'

  const ready = !isLoading && !error
  return (
    <>
      {/* Error Modal */}
      <Modal
        isOpen={!!error}
        title={error?.title || 'Undefined Error'}
        onClose={handleErrorModalClose}
        aria-labelledby="error-title"
        aria-describedby="error-description"
        classes="modal dramcorp"
      >
        <div>{error?.description || undefinedErrorMessage}</div>
      </Modal>
      {!authenticated && ready && <LoginModal isOpen />}
      {authenticated && ready && (
        <UserGetter user={user}>
          <FTUE />
          <div className="app-container">
            <header className="header flex align-center">
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <img
                alt="menu icon"
                className="header-menu-icon"
                src={MenuIcon}
                onClick={() => setMenuOpen(true)}
              />
              <img
                alt="Dramcorp logo"
                className="header-logo"
                src={DramcorpLogo}
              />

              <button
                type="button"
                className={`btn header-btn p-x-1 ${
                  activeView === 0 ? 'active' : ''
                }`}
                onClick={() => handleMenuClick(MENU_OPTIONS.Dashboard)}
              >
                Dashboard
              </button>
              <button
                type="button"
                className={`btn header-btn ${activeView === 1 ? 'active' : ''}`}
                onClick={() => handleMenuClick(MENU_OPTIONS.DramDrive)}
              >
                My DramDrive
              </button>
              <LogoutContainer />
            </header>

            {activeView === 0 && (
              <Fragment>
                <Bio userAlpha={userAlpha} />
                <AchievementsList />
              </Fragment>
            )}
            {activeView === 1 && <InventoryList />}
            <MobileMenu
              activeView={activeView}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              handleMenuClick={handleMenuClick}
            />
            <footer className="flex column align-center justify-between h-100">
              <div className="footer-gradient w-100" />

              <div className="flex footer-icons-container flex-grow-1 align-center justify-around p-y-2 w-100">
                <img
                  alt="Dramcorp logo"
                  className="footer-logo"
                  src={DramcorpLogo}
                />

                <div className="flex column m-t-3">
                  <a
                    className="text-center font-lg color-white decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.dramcorp.com/"
                  >
                    www.dramcorp.com
                  </a>
                </div>

                <div />
              </div>
            </footer>
            <SyncButton handleClick={handleAuthOverlayClick} />
          </div>
          <AchievementPathModal />
          <Auth0SyncSlideout
            isOpen={authOverlayOpen}
            handleClick={handleAuthOverlayClick}
          />
        </UserGetter>
      )}
    </>
  )
}

export default UserPortal
