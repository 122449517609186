import React, { FunctionComponent, PropsWithChildren } from 'react'
import CloseButton from './CloseButton'

interface Props {
  isOpen: boolean
  classes?: string
  title: string
  onClose?: () => void
}

const Modal: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  isOpen,
  classes = '',
  title,
  onClose,
}) =>
  isOpen ? (
    <div className="overlay" onClick={onClose}>
      <div className="overlay-inner flex justify-center align-center">
        <div className={`modal ${classes}`}>
          <div className="titlebar">
            <div className="icon-and-title">
              <div className="title">{title}</div>
            </div>
            <CloseButton onClick={onClose} />
          </div>
          <div className="content-wrapper">
            <div className="content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  ) : null

Modal.defaultProps = {
  classes: undefined,
  onClose: undefined,
}

export default Modal
