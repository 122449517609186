import React, { Fragment, FunctionComponent, useContext, useState } from 'react'
import { UserContext } from '../UserGetter'
import pages from './pages'

import BoopIcon from '../../assets/icons/icon-boop.svg'

const FTUE: FunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const { user, loading } = useContext(UserContext)

  if (loading) {
    return (
      <div className="overlay justify-center align-center">
        <div className="overlay-inner">
          <div className="color-white bold font-xl text-center m-t-4">
            <img className="loading-icon" alt="loading" src={BoopIcon} />
          </div>
        </div>
      </div>
    )
  }

  // setting currentPage > pages.length -1 will close FTUE
  if (currentPage > pages.length - 1) {
    return null
  }

  if (
    // check if user has any sort of inventory or cheevos
    user &&
    user.inventory.length + user.achievements.length > 0
  ) {
    return null
  }

  return (
    <Fragment>
      {
        <div className="ftue-overlay column">
          <div className="FTUE-wrapper flex column align-center w-100">
            <div className="FTUE w-100 m-t-3">
              <div
                className={`illustration flex justify-center page-${currentPage}`}
              >
                {pages[currentPage].illustrationArea}
              </div>
              <div className="blurb-and-controls">
                <div className="blurb bold text-center color-white m-x-auto w-70 text-center font-lg">
                  {pages[currentPage].blurb}
                </div>
                <div className="controls flex column ">
                  <div className="progress-container flex justify-between">
                    {pages.map((page, i) => (
                      <svg height="12" width="12" key={page.id}>
                        <circle
                          cx="6"
                          cy="6"
                          r="6"
                          stroke="#df4e38"
                          fill={`${currentPage === i ? '#df4e38' : '#0d0d20'}`}
                          strokeWidth="1"
                        />
                      </svg>
                    ))}
                  </div>
                  <div className="back-and-next flex p-x-2">
                    {currentPage > 0 && (
                      <button
                        type="button"
                        className="back ftue-btn invert color-white font-lg"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage <= 0}
                      >
                        Back
                      </button>
                    )}
                    <button
                      type="button"
                      className="next ftue-btn color-white font-lg"
                      onClick={() => {
                        // if (!user && currentPage === pages.length - 1) return
                        setCurrentPage(currentPage + 1)
                      }}
                    >
                      Next
                    </button>
                  </div>

                  <button
                    type="button"
                    className="skip font-lg m-x-auto m-t-1"
                    onClick={() => {
                      setCurrentPage(pages.length)
                    }}
                  >
                    Skip
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  )
}

export default FTUE
