import classNames from 'classnames'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useContext } from 'react'
import { UserContext } from './UserGetter'

interface Props {
  isOpen: boolean
  handleClick: () => void
}

const Auth0SyncSlideout: React.FunctionComponent<Props> = ({
  isOpen,
  handleClick,
}: Props) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  const { user } = useContext(UserContext)
  if (isAuthenticated) return <></>

  return (
    <div className={classNames('slide-out', { closed: !isOpen })}>
      <div className="content-container">
        <h2>Sync Achievements to your Meow Wolf account?</h2>
        <div className="flex justify-around">
          <button
            type="button"
            className="sync-option"
            onClick={() => handleClick()}
          >
            No Thanks
          </button>
          <button
            type="button"
            className="sync-option"
            onClick={() => {
              loginWithRedirect({
                authorizationParams: {
                  redirect_uri: `${window.location.origin}/?_userAlpha=${user?.identifier}`,
                  fragment: `?_id=${user?._id}`,
                },
              })
            }}
          >
            Sync Now
          </button>
        </div>
      </div>
    </div>
  )
}

export default Auth0SyncSlideout
