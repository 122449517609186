import classNames from 'classnames'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import Cookies from 'js-cookie'
import { useAuth0 } from '@auth0/auth0-react'
import Modal from '../Modal'
import DramcorpLogo from '../../assets/logos/logo-dramcorp.svg'
import { LOGIN_FAILURE_COOKIE } from '../../App'
import styles from './LoginModal.module.css'

interface Props {
  isOpen: boolean
}

const LoginModal: FunctionComponent<Props> = ({ isOpen }: Props) => {
  const input = useRef<HTMLInputElement | null>(null)
  const loginFailure = Cookies.get(LOGIN_FAILURE_COOKIE)
  const userAlphaRef = useRef('')

  const { isAuthenticated } = useAuth0()
  useEffect(() => {
    if (userAlphaRef.current && isAuthenticated) {
      window.location.href = `${window.location.origin}/${userAlphaRef.current}`
    }
  }, [userAlphaRef, isAuthenticated])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const userAlpha = params.get('_userAlpha')
    if (userAlpha) {
      userAlphaRef.current = userAlpha
    }
  }, [window?.location.search])

  const { loginWithRedirect } = useAuth0()

  return (
    <Modal
      isOpen={isOpen}
      title="Log in"
      classes={classNames({
        'login-modal': true,
        dramcorp: true,
      })}
    >
      <div className={classNames('button-container', styles.Auth0LoginBtn)}>
        <button
          type="submit"
          className="btn login"
          onClick={() =>
            loginWithRedirect({
              authorizationParams: {
                redirect_uri: window.location.origin,
              },
            })
          }
        >
          Log in
        </button>
      </div>
      <div className="grid grid-columns">
        <div className="grid left-pane">
          <div className="content-title">Employee Login</div>
          <div>Please log in with your Dramcorp credentials</div>
          <img src={DramcorpLogo} alt="Dramcorp" />
        </div>
        <form
          className="grid right-pane"
          onSubmit={(event) => {
            event.preventDefault()
            event.stopPropagation()
            if (input.current) {
              const { value } = input.current
              window.location.href = `${
                window.location.origin
              }/${value.toUpperCase()}`
            }
          }}
        >
          <div className="input-container">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="access-code">Access code</label>
            <input
              type="text"
              id="access-code"
              placeholder="Access code"
              ref={input}
            />
            {loginFailure && (
              <div className="login-error">
                Incorrect access code, please try again.
              </div>
            )}
          </div>
          <div className="button-container">
            <button type="submit" className="btn login">
              Send
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default LoginModal
