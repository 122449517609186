import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'

import './App.css'
import UserPortal from './UserPortal'

const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string

export const LOGIN_FAILURE_COOKIE = 'login_failure'

const App: React.FC = () => (
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <UserPortal />
  </Auth0Provider>
)

export default App
